import { useAppSelector } from '@redux/hooks';

import { Button, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Flex } from '@components';

import { authSelectors } from '@redux/auth';

import { checkUserCanReviewVideoInterview } from '@utils';

export const EmptyFeedbackState: React.FC<{
  resultDate: string | null;
  onProvideFeedbackClick: () => void;
}> = ({ resultDate, onProvideFeedbackClick }) => {
  const profile = useAppSelector(authSelectors.getProfileData);

  const isAbleToReview = checkUserCanReviewVideoInterview(profile);

  return (
    <Flex
      sx={(theme) => ({
        background: theme.palette.highlight.accent,
        borderRadius: '4px',
        width: '100%',
        minHeight: '240px',
        padding: '16px',
      })}
      flexDirection="column"
      justifyContent="center"
      gap={2}
    >
      {!isAbleToReview ? (
        <Typography textAlign="center">No feedback yet</Typography>
      ) : (
        <>
          <Button
            variant="contained"
            color="secondary"
            onClick={onProvideFeedbackClick}
            startIcon={<EditIcon fontSize="small" />}
          >
            Provide feedback
          </Button>
          <Typography textAlign="center">
            Please provide feedback and resolution
            {resultDate ? `before Sep 4` : ''} to proceed to the technical
            interview.
          </Typography>
        </>
      )}
    </Flex>
  );
};
