import { candidateDetailsStoreKey } from './candidateDetails.const';
import { ICandidateDetailsSliceState } from './candidateDetails.types';

interface IState {
  [candidateDetailsStoreKey]: ICandidateDetailsSliceState;
}

const selectCandidateDetailsState = (
  state: IState,
): ICandidateDetailsSliceState => {
  return state[candidateDetailsStoreKey];
};

const getCandidateDetailsId = (state: IState) => {
  return selectCandidateDetailsState(state).id;
};

const getCandidateDetailsApiStatus = (state: IState) => {
  return selectCandidateDetailsState(state).apiStatus;
};

const getCandidateDetailsData = (state: IState) => {
  return selectCandidateDetailsState(state).data;
};

// Suitable Positions
const getCandidateSuitablePositionsApiStatus = (state: IState) => {
  return selectCandidateDetailsState(state).suitablePositionsAPIStatus;
};

const getCandidateSuitablePositionsData = (state: IState) => {
  return selectCandidateDetailsState(state).suitablePositionsData;
};

// Video Interview
const getCandidateVideoInterviewApiStatus = (state: IState) => {
  return selectCandidateDetailsState(state).videoInterviewAPIStatus;
};

const getCandidateVideoInterviewActionPerforming = (state: IState) => {
  return selectCandidateDetailsState(state).isPerformingVideoInterviewAction;
};

const getCandidateVideoInterviewData = (state: IState) => {
  return selectCandidateDetailsState(state).videoInterviewData;
};

// Knowledge Quiz
const getCandidateKnowledgeQuizApiStatus = (state: IState) => {
  return selectCandidateDetailsState(state).knowledgeQuizAPIStatus;
};

const getCandidateKnowledgeQuizData = (state: IState) => {
  return selectCandidateDetailsState(state).knowledgeQuizData;
};

// Test Tasks
const getCandidateTestTasksApiStatus = (state: IState) => {
  return selectCandidateDetailsState(state).testTasksAPIStatus;
};

const getCandidateTestTaskActionPerforming = (state: IState) => {
  return selectCandidateDetailsState(state).isPerformingTestTaskAction;
};

const getCandidateTestTasksData = (state: IState) => {
  return selectCandidateDetailsState(state).testTasksData;
};

const getCandidateAssociationsAPIData = (state: IState) => {
  return {
    associationAPIStatus:
      selectCandidateDetailsState(state).associationAPIStatus,
    associationErrorMessage:
      selectCandidateDetailsState(state).associationErrorMessage,
  };
};

const getUpdateCandidateStatusAPIData = (state: IState) => {
  return {
    updateStatusAPIStatus:
      selectCandidateDetailsState(state).updateStatusAPIStatus,
    updateStatusErrorMessage:
      selectCandidateDetailsState(state).updateStatusErrorMessage,
  };
};

const getProcessActivity = (state: IState) => {
  return {
    processActivityAPIStatus:
      selectCandidateDetailsState(state).processActivityAPIStatus,
    processActivity: selectCandidateDetailsState(state).processActivity,
  };
};

const getMeetings = (state: IState) => {
  return {
    meetingsAPIStatus: selectCandidateDetailsState(state).meetingsAPIStatus,
    meetings: selectCandidateDetailsState(state).meetings,
  };
};

const getMeetingsReviewLoading = (state: IState) => {
  return selectCandidateDetailsState(state).isMeetingReviewLoading;
};

const getGenerateClientVideoLinkApiStatus = (state: IState) => {
  return selectCandidateDetailsState(state).generateClientVideoLinkAPIStatus;
};

const getResetVideoInterviewAPIStatus = (state: IState) => {
  return selectCandidateDetailsState(state).resetVideoInterviewAPIStatus;
};

const getSendVideoInterviewAPIStatus = (state: IState) => {
  return selectCandidateDetailsState(state).sendVideoInterviewAPIStatus;
};

const getIsProfileUpdating = (state: IState) => {
  return selectCandidateDetailsState(state).isProfileUpdating;
};

const getIsTestTaskFormProcesing = (state: IState) => {
  return selectCandidateDetailsState(state).isTestTaskFormProcesing;
};

const getCandidateSubmissions = (state: IState) => {
  return {
    submissionsAPIStatus:
      selectCandidateDetailsState(state).submissionsAPIStatus,
    submissions: selectCandidateDetailsState(state).submissions,
  };
};

const getHotPipelineCandidateInviteAPIStatus = (state: IState) => {
  return selectCandidateDetailsState(state).hotPipelineCandidateInviteAPIStatus;
};

const getIsCandidateInHotPipeline = (state: IState) => {
  return selectCandidateDetailsState(state).data?.Hot_Pipeline;
};

const getCreateCandidateAPIStatus = (state: IState) => {
  return selectCandidateDetailsState(state).apiStatus;
};

const getCandidateDuplicatesAPIData = (state: IState) => {
  return {
    duplicateCandidatesAPIStatus:
      selectCandidateDetailsState(state).duplicateCandidatesAPIStatus,
    duplicateCandidates: selectCandidateDetailsState(state).duplicateCandidates,
  };
};

const getCandidateLPinvitationAPIStatus = (state: IState) => {
  return selectCandidateDetailsState(state).sendLPInvitationAPIStatus;
};

const getCandidateNotes = (state: IState) => {
  return {
    candidateNotesAPIStatus:
      selectCandidateDetailsState(state).candidateNotesAPIStatus,
    candidateNotes: selectCandidateDetailsState(state).candidateNotes,
  };
};

export const candidateDetailsSelectors = {
  getCandidateDetailsApiStatus,
  getCandidateDetailsId,
  getCandidateDetailsData,
  getHotPipelineCandidateInviteAPIStatus,
  getIsCandidateInHotPipeline,
  getCandidateSuitablePositionsApiStatus,
  getCandidateSuitablePositionsData,

  getCandidateVideoInterviewApiStatus,
  getCandidateVideoInterviewActionPerforming,
  getCandidateVideoInterviewData,

  getCandidateKnowledgeQuizApiStatus,
  getCandidateKnowledgeQuizData,

  getCandidateTestTasksApiStatus,
  getCandidateTestTaskActionPerforming,
  getCandidateTestTasksData,

  getCandidateAssociationsAPIData,

  getUpdateCandidateStatusAPIData,

  getProcessActivity,

  getMeetings,
  getMeetingsReviewLoading,

  getResetVideoInterviewAPIStatus,

  getSendVideoInterviewAPIStatus,

  getIsProfileUpdating,

  getIsTestTaskFormProcesing,

  getGenerateClientVideoLinkApiStatus,

  getCandidateSubmissions,

  getCreateCandidateAPIStatus,

  getCandidateDuplicatesAPIData,

  getCandidateLPinvitationAPIStatus,

  getCandidateNotes,
};
