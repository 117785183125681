import { useFormik } from 'formik';
import { useAppSelector } from '@redux/hooks';

import { CustomButton, FlatReviewOption, Flex } from '@components';
import CloseIcon from '@mui/icons-material/Close';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CommentsDisabledOutlinedIcon from '@mui/icons-material/CommentsDisabledOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

import { authSelectors } from '@redux/auth';

import {
  ReviewLevels,
  VIResolution,
  VIResolutionOptionsList,
} from '@constants';
import { CandidateVideoInterview, ReviewVideoInterviewPayload } from '@types';
import { generateVIRejectionEmail } from '@utils';

import { validationSchema } from './validationSchema';

export const VideoInterviewReviewModal: React.FC<{
  isOpen: boolean;
  candidateFirstName: string;
  videoInterviewData: CandidateVideoInterview;
  isLoading: boolean;
  handleClose: () => void;
  handleSubmit: (data: ReviewVideoInterviewPayload) => void;
}> = ({
  isOpen,
  candidateFirstName,
  videoInterviewData,
  isLoading,
  handleClose,
  handleSubmit,
}) => {
  const profile = useAppSelector(authSelectors.getProfileData);
  const email = generateVIRejectionEmail(candidateFirstName);

  const formik = useFormik<{
    presentationSkillsLevel: ReviewLevels | '';
    functionalExpertiseLevel: ReviewLevels | '';
    englishLevel: ReviewLevels | '';
    feedback?: string;
    resolution: VIResolution | '';
    reviewer: string;
    sendRejectionEmail: boolean;
    emailSubject: string;
    emailContent: string;
  }>({
    initialValues: {
      presentationSkillsLevel: videoInterviewData.presentationSkillsLevel || '',
      functionalExpertiseLevel:
        videoInterviewData.functionalExpertiseLevel || '',
      englishLevel: videoInterviewData.englishLevel || '',
      feedback: videoInterviewData.feedback || '',
      resolution: videoInterviewData.resolution ?? '',
      reviewer: videoInterviewData.reviewer || profile?.email || '',
      sendRejectionEmail: false,
      emailSubject: email.subject,
      emailContent: email.content,
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit({
        presentationSkillsLevel: values.presentationSkillsLevel as ReviewLevels,
        functionalExpertiseLevel:
          values.functionalExpertiseLevel as ReviewLevels,
        englishLevel: values.englishLevel as ReviewLevels,
        feedback: values.feedback,
        resolution: values.resolution as string,
        isPassed: values.resolution === (VIResolution.PASSED as string),
        emailSubject: values.sendRejectionEmail ? values.emailSubject : null,
        emailContent: values.sendRejectionEmail ? values.emailContent : null,
      });
    },
    validateOnMount: true,
  });

  return (
    <Dialog open={isOpen} scroll="body" fullWidth>
      <DialogTitle>
        <Flex justifyContent="space-between">
          <Typography variant="h2">Your feedback</Typography>
          <CloseIcon
            onClick={handleClose}
            sx={{ cursor: 'pointer', color: 'rgba(0,0,0,.38)' }}
          />
        </Flex>
      </DialogTitle>
      <DialogContent>
        <Flex flexDirection="column" gap={2} width="100%">
          <Flex flexDirection="column" gap={1} width="100%">
            <FlatReviewOption
              type={0}
              name="presentationSkillsLevel"
              title="Presentation skills"
              selected={formik.values.presentationSkillsLevel}
              onChange={formik.setFieldValue}
            />
            <FlatReviewOption
              type={0}
              name="functionalExpertiseLevel"
              title="Functional expertise"
              selected={formik.values.functionalExpertiseLevel}
              onChange={formik.setFieldValue}
            />
            <FlatReviewOption
              type={0}
              name="englishLevel"
              title="English level"
              selected={formik.values.englishLevel}
              onChange={formik.setFieldValue}
            />
          </Flex>
          <TextField
            select
            id="select-final-resolution"
            name="resolution"
            value={formik.values.resolution}
            label={
              <>
                Final resolution<sup>*</sup>
              </>
            }
            onChange={(e) => {
              const resolution = e.target.value as VIResolution;

              formik.setValues({
                ...formik.values,
                resolution,
                sendRejectionEmail: resolution !== VIResolution.PASSED,
              });
            }}
            onBlur={formik.handleBlur}
            error={
              formik.touched.resolution && Boolean(formik.errors.resolution)
            }
            SelectProps={{
              notched: true,
              displayEmpty: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          >
            <MenuItem disabled value="">
              Please select
            </MenuItem>
            {VIResolutionOptionsList.map((opt) => (
              <MenuItem key={opt.value} value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </TextField>
          {!!formik.values.resolution &&
            formik.values.resolution !== VIResolution.PASSED && (
              <Flex flexDirection="column" gap={2} width="100%">
                <Alert
                  variant="standard"
                  severity={
                    formik.values.sendRejectionEmail ? 'info' : 'warning'
                  }
                  icon={
                    formik.values.sendRejectionEmail ? (
                      <MailOutlineIcon />
                    ) : (
                      <CommentsDisabledOutlinedIcon />
                    )
                  }
                  action={
                    <CustomButton
                      label={
                        formik.values.sendRejectionEmail
                          ? "Don't send"
                          : 'Turn on sending'
                      }
                      color="secondary"
                      size="small"
                      startIcon={
                        formik.values.sendRejectionEmail ? (
                          <RemoveCircleOutlineIcon />
                        ) : (
                          <AddCircleOutlineIcon />
                        )
                      }
                      onClick={() =>
                        formik.setFieldValue(
                          'sendRejectionEmail',
                          !formik.values.sendRejectionEmail,
                        )
                      }
                    />
                  }
                  sx={{ width: '100%' }}
                >
                  {formik.values.sendRejectionEmail
                    ? 'Candidate will receive one time rejection email. Feel free to adjust the message according to the situation:'
                    : 'Candidate will NOT receive a rejection email'}
                </Alert>

                {formik.values.sendRejectionEmail && (
                  <>
                    <TextField
                      name="emailSubject"
                      label="Subject"
                      variant="outlined"
                      fullWidth
                      value={formik.values.emailSubject || ''}
                      required
                      onChange={(e) =>
                        formik.setFieldValue(
                          'emailSubject',
                          e.target.value || null,
                        )
                      }
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.emailSubject &&
                        !!formik.errors.emailSubject
                      }
                      helperText={
                        formik.touched.emailSubject
                          ? formik.errors.emailSubject
                          : undefined
                      }
                    />
                    <TextField
                      name="emailContent"
                      label="Rejection email"
                      variant="outlined"
                      fullWidth
                      value={formik.values.emailContent || ''}
                      required
                      onChange={(e) =>
                        formik.setFieldValue(
                          'emailContent',
                          e.target.value || null,
                        )
                      }
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.emailContent &&
                        !!formik.errors.emailContent
                      }
                      helperText={
                        formik.touched.emailContent
                          ? formik.errors.emailContent
                          : undefined
                      }
                      multiline
                      minRows={6}
                    />
                  </>
                )}
              </Flex>
            )}

          <TextField
            name="feedback"
            label="Notes"
            type="textarea"
            placeholder="Add your feedback"
            id="feedback"
            variant="outlined"
            value={formik.values.feedback}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.feedback && Boolean(formik.errors.feedback)}
            multiline
            required
            minRows={2}
            helperText="Feedback will be added to candidates Zoho record"
            fullWidth
          />
          <TextField
            name="reviewer"
            label="Reviewer"
            id="reviewer"
            variant="outlined"
            value={formik.values.reviewer}
            fullWidth
            disabled
          />
        </Flex>
      </DialogContent>
      <DialogActions>
        <Flex justifyContent="end" gap={1}>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => formik.handleSubmit()}
            disabled={isLoading}
          >
            Save feedback
          </Button>
        </Flex>
      </DialogActions>
    </Dialog>
  );
};
