import { useCallback, useEffect, useState, useRef } from 'react';
import { DraggableList, Flex } from '@components';
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import { TransferListOption } from '@types';
import { groupBy, mapValues, values } from 'lodash';
import { ColumnLayout } from './components/ColumnLayout';
import { useKeyboardListNavigation } from '@hooks';

export const TransferList: React.FC<{
  options: TransferListOption[];
  selectedOptions: TransferListOption[];
  onChange: (selectedOptions: TransferListOption[]) => void;
}> = ({ options, selectedOptions, onChange }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [draggableState, setDraggableState] = useState<TransferListOption[]>(
    [],
  );

  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setDraggableState(selectedOptions);
  }, [selectedOptions]);

  const filteredOptions = options
    .filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase()),
    )
    .sort((a, b) => {
      const groupCompare = (a.group || '').localeCompare(b.group || '');
      return groupCompare === 0 ? a.label.localeCompare(b.label) : groupCompare;
    });

  const selectableOptions = filteredOptions.filter(
    (option) =>
      !selectedOptions.some((selected) => selected.value === option.value),
  );

  const { focusedIndex, listItemRefs, handleKeyDown, handleItemClick } =
    useKeyboardListNavigation({
      items: selectableOptions,
      onSelect: (option) => onChange([...selectedOptions, option]),
      isItemDisabled: (option) =>
        selectedOptions.some((selected) => selected.value === option.value),
      shouldFocusOnMount: false,
      shouldFocusFirstItemAfterSelect: true,
    });

  const onRemoveOptionClick = useCallback(
    (option: TransferListOption) => {
      onChange(selectedOptions.filter((e) => e.value !== option.value));
    },
    [onChange, selectedOptions],
  );

  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    const reorderedOptions = Array.from(draggableState);
    const [movedOption] = reorderedOptions.splice(result.source.index, 1);
    reorderedOptions.splice(result.destination.index, 0, movedOption);
    setDraggableState(reorderedOptions);
    onChange(reorderedOptions);
  };

  const selectableIndexMap = new Map(
    selectableOptions.map((option, index) => [option.value, index]),
  );

  return (
    <Flex height="100%" mx={-3} onKeyDown={handleKeyDown}>
      <ColumnLayout
        side="left"
        headerComponent={
          <Box
            sx={{
              backgroundColor: 'white',
              position: 'sticky',
              marginTop: '10px',
              width: '100%',
              top: 0,
              zIndex: 1000,
              paddingX: '12px',
            }}
          >
            <TextField
              inputRef={searchInputRef}
              variant="standard"
              sx={{
                width: '100%',
                position: 'sticky',
                padding: '5px',
                backgroundColor: 'white',
                '& .MuiInputBase-input': {
                  paddingLeft: '8px',
                },
                '& .MuiInput-underline:before': {
                  borderBottomColor: '#3F8CFF',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#3F8CFF',
                },
              }}
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                type: 'search',
                startAdornment: (
                  <SearchIcon color="secondary" fontSize="small" />
                ),
              }}
            />
          </Box>
        }
        contentComponent={
          <>
            {filteredOptions.length === 0 && searchTerm && (
              <Typography
                variant="body2"
                color="text.danger"
                padding="16px 16px 4px 18px"
              >
                No columns found
              </Typography>
            )}
            {values(
              mapValues(
                groupBy(filteredOptions, 'group'),
                (groupOptions, groupName) => (
                  <Box key={groupName}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      padding="16px 16px 4px 18px"
                    >
                      {groupName}
                    </Typography>
                    <List
                      dense
                      component="div"
                      role="list"
                      sx={{ paddingTop: 0 }}
                    >
                      {groupOptions
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map((option) => {
                          const labelId = `transfer-list-item-${option.value}-label`;
                          const isDisabled = selectedOptions.some(
                            (e) => e.value === option.value,
                          );
                          const isChecked =
                            selectedOptions.some(
                              (e) => e.value === option.value,
                            ) || isDisabled;

                          const selectableIndex = selectableIndexMap.get(
                            option.value,
                          );

                          return (
                            <ListItemButton
                              ref={(el) => {
                                if (
                                  !isDisabled &&
                                  selectableIndex !== undefined
                                ) {
                                  listItemRefs.current[selectableIndex] = el;
                                }
                              }}
                              key={option.value}
                              role="listitem"
                              onClick={() => handleItemClick(option)}
                              disabled={isDisabled}
                              tabIndex={
                                selectableIndex === focusedIndex ? 0 : -1
                              }
                              sx={{
                                '&:focus': {
                                  backgroundColor: '#3F8CFF14',
                                  '& .MuiListItemIcon-root': {
                                    color: '#3F8CFF',
                                  },
                                },
                                '&:hover': {
                                  backgroundColor: '#3F8CFF14',
                                  '& .MuiListItemIcon-root': {
                                    color: '#3F8CFF',
                                  },
                                },
                              }}
                            >
                              <ListItemIcon sx={{ minWidth: '32px' }}>
                                {isChecked ? (
                                  <CheckIcon fontSize="small" />
                                ) : (
                                  <AddIcon fontSize="small" />
                                )}
                              </ListItemIcon>
                              <ListItemText
                                id={labelId}
                                primary={option.label}
                              />
                            </ListItemButton>
                          );
                        })}
                    </List>
                  </Box>
                ),
              ),
            )}
          </>
        }
      />
      <ColumnLayout
        side="right"
        contentComponent={
          <>
            <Typography
              variant="body2"
              color="text.secondary"
              padding="16px 16px 4px 18px"
            >
              Selected columns
            </Typography>
            <Box sx={{ overflow: 'hidden' }}>
              <DraggableList
                items={draggableState}
                onDragEnd={onDragEnd}
                onDelete={onRemoveOptionClick}
              />
            </Box>
          </>
        }
      />
    </Flex>
  );
};
