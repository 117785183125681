import React from 'react';
import { useAppSelector } from '@redux/hooks';
import { isNumber } from 'lodash';

import { Box, Link, Stack, Typography } from '@mui/material';
import {
  CountryFlag,
  CrucialRequirementChip,
  Flex,
  RowLayout,
  VideoInterviewQuestionPreview,
} from '@components';

import { jobOpeningDetailsSelectors } from '@redux/jobOpeningDetails';
import { JobOpeningFieldsLabels } from '@constants';
import { isStatusLoading } from '@utils';

export const JobOpeningInfo: React.FC = () => {
  const jobOpeningDetails = useAppSelector(
    jobOpeningDetailsSelectors.getJobOpeningDetailsData,
  );
  const joDetailsAPIStatus = useAppSelector(
    jobOpeningDetailsSelectors.getJobOpeningDetailsApiStatus,
  );

  const isLoadingApi = isStatusLoading(joDetailsAPIStatus);

  return (
    <Box sx={{ mt: 0.5, mb: 0.5 }}>
      <RowLayout
        label="Client"
        isEmpty={!jobOpeningDetails?.Client_Name_New}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Client_Name_New || '-'}
          </Typography>
        }
      />
      <RowLayout
        label="Hiring manager"
        isEmpty={!jobOpeningDetails?.Hiring_M}
        Component={
          <Stack
            direction={'row'}
            gap={1}
            flexWrap={'wrap'}
            alignItems="baseline"
          >
            <Typography variant="body1">
              {jobOpeningDetails?.Hiring_M}
            </Typography>
            {jobOpeningDetails?.Hiring_M !==
              jobOpeningDetails?.Hiring_Manager_email && (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ textDecoration: 'underline' }}
              >
                {jobOpeningDetails?.Hiring_Manager_email ? (
                  <Link
                    href={`mailto:${jobOpeningDetails?.Hiring_Manager_email}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    {jobOpeningDetails?.Hiring_Manager_email}
                  </Link>
                ) : (
                  <Link style={{ textDecoration: 'none', color: 'inherit' }}>
                    {'No email'}
                  </Link>
                )}
              </Typography>
            )}
          </Stack>
        }
      />
      <RowLayout
        label="PDM"
        isEmpty={!jobOpeningDetails?.PDM}
        Component={
          <Typography variant="body1">{jobOpeningDetails?.PDM}</Typography>
        }
      />
      <RowLayout
        label="Client recruiter"
        isEmpty={!jobOpeningDetails?.Responsible_Recruiter?.length}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Responsible_Recruiter?.join(', ')}
          </Typography>
        }
      />
      <RowLayout
        label="Interviewer"
        isEmpty={!jobOpeningDetails?.Technical_Reviewer}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Technical_Reviewer}
          </Typography>
        }
      />
      <RowLayout
        label="Additional Interviewer"
        isEmpty={!jobOpeningDetails?.Additional_Technical_Interviewer}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Additional_Technical_Interviewer}
          </Typography>
        }
      />
      <RowLayout
        label="Status"
        isEmpty={!jobOpeningDetails?.Job_Opening_Status}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Job_Opening_Status}
          </Typography>
        }
      />
      <RowLayout
        label="Opened official"
        isEmpty={!jobOpeningDetails?.Date_Opened_Official}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Date_Opened_Official}
          </Typography>
        }
      />
      <RowLayout
        label="Pre-opened"
        isEmpty={!jobOpeningDetails?.Date_Pre_opened}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Date_Pre_opened}
          </Typography>
        }
      />
      <RowLayout
        label="Date opened"
        isEmpty={!jobOpeningDetails?.Date_Opened}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Date_Opened}
          </Typography>
        }
      />
      <RowLayout
        label="Days since opening"
        isEmpty={!jobOpeningDetails?.Days_since_opening_new}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Days_since_opening_new}
          </Typography>
        }
      />
      <RowLayout
        label="Locations"
        isEmpty={!jobOpeningDetails?.Location_Area_Test?.length}
        Component={
          <>
            {jobOpeningDetails?.Location_Area_Test?.map((country: string) => (
              <CountryFlag country={country} key={country} />
            ))}
          </>
        }
      />
      <RowLayout
        label="Location tier outreach"
        isEmpty={!jobOpeningDetails?.Location_Tier_Outreach?.length}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Location_Tier_Outreach?.join(', ')}
          </Typography>
        }
      />
      <RowLayout
        label="Specialization"
        isEmpty={!jobOpeningDetails?.Specialization}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Specialization}
          </Typography>
        }
      />
      <RowLayout
        label="LP specializations"
        isEmpty={!jobOpeningDetails?.LP_Specializations?.length}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.LP_Specializations?.join(', ')}
          </Typography>
        }
      />
      <RowLayout
        label="Technical flow"
        isEmpty={!jobOpeningDetails?.Technical_Flow}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Technical_Flow}
          </Typography>
        }
      />
      <RowLayout
        label="Seniority"
        isEmpty={!jobOpeningDetails?.Seniority_level?.length}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Seniority_level?.join(', ')}
          </Typography>
        }
      />
      <RowLayout
        label={JobOpeningFieldsLabels.Interview_process}
        isEmpty={!jobOpeningDetails?.Interview_process}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Interview_process}
          </Typography>
        }
      />
      <RowLayout
        label={JobOpeningFieldsLabels.Candidates_spreadsheet}
        isEmpty={!jobOpeningDetails?.Candidates_spreadsheet}
        Component={
          <Link
            variant="body1"
            target="_blank"
            color="text.link"
            sx={{ cursor: 'pointer' }}
            href={jobOpeningDetails?.Candidates_spreadsheet ?? '#'}
          >
            {jobOpeningDetails?.Candidates_spreadsheet}
          </Link>
        }
      />
      <RowLayout
        label={JobOpeningFieldsLabels.Jira_url}
        isEmpty={!jobOpeningDetails?.Jira_url}
        Component={
          <Link
            variant="body1"
            target="_blank"
            color="text.link"
            sx={{ cursor: 'pointer' }}
            href={jobOpeningDetails?.Jira_url ?? '#'}
          >
            {jobOpeningDetails?.Jira_url}
          </Link>
        }
      />
      <RowLayout
        label="Available intro calls"
        isEmpty={isLoadingApi}
        Component={
          <Typography variant="body1">
            {isNumber(jobOpeningDetails?.maxCalls)
              ? `${jobOpeningDetails?.availableCalls}/${jobOpeningDetails?.maxCalls}`
              : 'No Limits'}
          </Typography>
        }
      />

      <RowLayout
        label={JobOpeningFieldsLabels.Other_Comments}
        isEmpty={!jobOpeningDetails?.Other_Comments}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Other_Comments}
          </Typography>
        }
      />

      <RowLayout
        label={JobOpeningFieldsLabels.crucialRequirements}
        isEmpty={!jobOpeningDetails?.crucialRequirements?.length}
        Component={
          <Flex gap={1} flexWrap="wrap">
            {jobOpeningDetails?.crucialRequirements?.map((requirement) => (
              <CrucialRequirementChip
                key={requirement.name}
                name={requirement.name}
                category={requirement.category}
                priority={requirement.priority}
              />
            ))}
          </Flex>
        }
      />

      <VideoInterviewQuestionPreview
        label={JobOpeningFieldsLabels.asyncVideoInterviewQuestions}
        videoInterviewQuestionsList={
          jobOpeningDetails?.asyncVideoInterviewQuestions?.questions || null
        }
        specialization={
          jobOpeningDetails?.asyncVideoInterviewQuestions?.specialization ||
          null
        }
        isCustom={jobOpeningDetails?.asyncVideoInterviewQuestions?.isCustom}
      />

      <RowLayout
        label={JobOpeningFieldsLabels.Vendors_involved}
        isEmpty={!jobOpeningDetails?.Vendors_involved}
        Component={
          <Typography variant="body1">
            {jobOpeningDetails?.Vendors_involved}
          </Typography>
        }
      />
    </Box>
  );
};
