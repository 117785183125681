import { Grid, Paper, Typography } from '@mui/material';
import { JobOpeningField } from '@components';

import { JobOpeningFormik } from '@types';
import { useAppSelector } from '@redux/hooks';
import { optionsSelectors } from '@redux/options';

export const SpecializationDetails: React.FC<{
  formik: JobOpeningFormik;
}> = ({ formik }) => {
  const { data: specializations } = useAppSelector(
    optionsSelectors.getJobOpeningSpecializations,
  );

  return (
    <Paper elevation={1} sx={{ mb: '1px', pt: 4, pb: 6, paddingX: 5 }}>
      <Grid container>
        <Grid item>
          <Typography variant="h2" mb={2}>
            Specialization and experience
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <JobOpeningField
            field="Specialization"
            value={formik.values.Specialization}
            required
            touched={formik.touched.Specialization}
            error={formik.errors.Specialization}
            onChange={(val) => {
              const selectedSpecialization = specializations.find(
                (e) => e.value === val,
              );

              formik.setValues({
                ...formik.values,
                Specialization: val || null,
                Technical_Flow: selectedSpecialization?.technicalFlow || null,
              });
            }}
            onBlur={() => formik.setFieldTouched('Specialization')}
          />
        </Grid>
        <Grid item xs={12} md={4} display="flex" alignItems="end">
          <JobOpeningField
            field="Technical_Flow"
            value={formik.values.Technical_Flow}
            required
            touched={formik.touched.Technical_Flow}
            error={formik.errors.Technical_Flow}
            onChange={(val) =>
              formik.setFieldValue('Technical_Flow', val || null)
            }
            onBlur={() => formik.setFieldTouched('Technical_Flow')}
            filterOptions={(data) =>
              data.filter(
                ({ specialization }) =>
                  specialization === formik.values.Specialization,
              )
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} marginTop={2}>
        <Grid item xs={12} md={4}>
          <JobOpeningField
            field="LP_Specializations"
            value={formik.values.LP_Specializations}
            required
            touched={formik.touched.LP_Specializations}
            error={formik.errors.LP_Specializations}
            onChange={(val) => {
              formik.setFieldValue('LP_Specializations', val || null);
            }}
            onBlur={() => formik.setFieldTouched('LP_Specializations')}
          />
        </Grid>
      </Grid>
      <JobOpeningField
        field="Seniority_level"
        value={formik.values.Seniority_level}
        touched={formik.touched.Seniority_level}
        error={formik.errors.Seniority_level}
        onChange={(val) => formik.setFieldValue('Seniority_level', val || null)}
        onBlur={() => formik.setFieldTouched('Seniority_level')}
      />
    </Paper>
  );
};
