import { useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import {
  Button,
  List,
  ListItem,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PhotoSizeSelectSmallOutlinedIcon from '@mui/icons-material/PhotoSizeSelectSmallOutlined';

import { Flex } from '@components';
import { VideoInterviewSendConfirmationModal } from './VideoInterviewSendConfirmationModal';

import {
  candidateDetailsSelectors,
  postCandidateSendVideoInterview,
} from '@redux/candidateDetails';

import { isStatusLoading } from '@utils';
import {
  CandidateVideoInterview,
  IJobOpeningVideoInterview,
  VideoInterviewQuestion,
} from '@types';

export const VideoInterviewSendForm: React.FC<{
  availableVideoInterview: IJobOpeningVideoInterview[];
  currentVideoInterview: CandidateVideoInterview | null;
}> = ({ availableVideoInterview, currentVideoInterview }) => {
  const dispatch = useAppDispatch();

  const candidateId = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsId,
  );
  const sendVideoInterviewAPIStatus = useAppSelector(
    candidateDetailsSelectors.getSendVideoInterviewAPIStatus,
  );

  const [videoInterview, setVideoInterview] = useState('');
  const [showVIQuestions, setShowVIQuestions] = useState(false);
  const [videoInterviewSendModal, setVideoInterviewSendModal] = useState(false);

  const selectedVideoInterview = useMemo(() => {
    return availableVideoInterview.find((vi) => vi.id === videoInterview);
  }, [availableVideoInterview, videoInterview]);

  const handleOpenVideoInterviewSendModal = useCallback(
    () => setVideoInterviewSendModal(true),
    [],
  );

  const handleCloseVideoInterviewSendModal = useCallback(
    () => setVideoInterviewSendModal(false),
    [],
  );

  const sendVideoInterview = useCallback(() => {
    if (candidateId && selectedVideoInterview) {
      dispatch(
        postCandidateSendVideoInterview({
          candidateId,
          videoInterviewId: selectedVideoInterview?.id,
        }),
      );
    }
  }, [candidateId, selectedVideoInterview]);

  return (
    <>
      <Flex flexDirection="column" gap={1}>
        <TextField
          select
          fullWidth
          label="VI questions"
          value={
            currentVideoInterview ? 'currentVideoInterview' : videoInterview
          }
          onChange={(e) => setVideoInterview(e.target.value)}
          InputLabelProps={{ shrink: true }}
          SelectProps={{
            displayEmpty: true,
          }}
          disabled={!!currentVideoInterview}
        >
          <MenuItem value="">Select job opening</MenuItem>
          {!!currentVideoInterview && (
            <MenuItem value="currentVideoInterview">
              {currentVideoInterview.videoInterview?.specialization}
              {currentVideoInterview.videoInterview?.isCustom
                ? ' (Custom)'
                : ''}
            </MenuItem>
          )}
          {availableVideoInterview.map((videoInterview) => (
            <MenuItem key={videoInterview.id} value={videoInterview.id}>
              {videoInterview.positionId}: {videoInterview.positionName} -{' '}
              {videoInterview.specialization}
              {videoInterview.isCustom ? '(Custom)' : ''}
            </MenuItem>
          ))}
        </TextField>
        {!currentVideoInterview && (
          <Flex justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              startIcon={<VideoCallIcon />}
              onClick={handleOpenVideoInterviewSendModal}
              disabled={!videoInterview}
            >
              Send invitation
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={
                showVIQuestions ? (
                  <PhotoSizeSelectSmallOutlinedIcon fontSize="small" />
                ) : (
                  <FormatListBulletedIcon />
                )
              }
              onClick={() => setShowVIQuestions(!showVIQuestions)}
              disabled={!videoInterview}
            >
              {showVIQuestions ? 'Hide' : 'View'} questions
            </Button>
          </Flex>
        )}
        {!currentVideoInterview && showVIQuestions && (
          <List
            sx={{
              padding: 0,
              listStyle: 'none',
            }}
          >
            {selectedVideoInterview?.questions.map(
              (question: VideoInterviewQuestion) => {
                return (
                  <ListItem
                    key={question.questionNumber}
                    sx={(theme) => ({
                      padding: '0.5rem 1rem 1rem 1rem !important',
                      marginBottom: '0.25rem !important',
                      borderRadius: '0.25rem',
                      background: theme.palette.highlight.actionable,
                    })}
                  >
                    <Typography variant="body2" color="text.secondary">
                      Question {question.questionNumber}
                    </Typography>
                    <Typography variant="body1">{question.title}</Typography>
                  </ListItem>
                );
              },
            )}
          </List>
        )}
      </Flex>
      <VideoInterviewSendConfirmationModal
        isLoading={isStatusLoading(sendVideoInterviewAPIStatus)}
        isOpen={videoInterviewSendModal}
        handleClose={handleCloseVideoInterviewSendModal}
        handleSendVI={sendVideoInterview}
      />
    </>
  );
};
