import { Box, Button, Typography } from '@mui/material';
import { ReactNode } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export const StageButton: React.FC<{
  amount: number | string;
  amountIcon?: ReactNode;
  active: boolean;
  onClick: () => void;
  disabled: boolean;
  variant?: string;
}> = ({
  amount,
  amountIcon,
  active,
  onClick,
  disabled,
  variant = 'medium',
}) => {
  const getButtonStyles = (theme: any) => {
    const baseStyles = {
      borderRadius: 2,
      width: '100%',
      color: 'rgba(0, 0, 0, 0.87)',
      background: active
        ? theme.palette.background.brandLight
        : disabled
        ? 'transparent'
        : theme.palette.highlight.actionable,
      fontWeight: 400,
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      justifyContent: 'flex-start',
      marginTop: 0.5,
      border: '2px solid white',
    };

    switch (variant) {
      case 'small':
        return {
          ...baseStyles,
          height: '30px',
          fontSize: '0.875rem',
          fontWeight: 400,
          marginRight: 0,
          width: '80%',
          padding: '4px',
        };
      case 'large':
        return {
          ...baseStyles,
          height: '45px',
        };
      default:
        return {
          ...baseStyles,
          height: '45px',
        };
    }
  };

  return (
    <Button
      variant="text"
      disabled={disabled}
      sx={(theme) => getButtonStyles(theme)}
      onClick={onClick}
    >
      <Box
        sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
      >
        <Typography>
          {amount}
          {amountIcon}
        </Typography>

        {active && <CloseRoundedIcon color={'secondary'} fontSize="small" />}
      </Box>
    </Button>
  );
};
