import { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';
import { CustomButton, Flex } from '@components';
import { VideoInterviewResetModal } from './components/VideoInterviewResetModal';

import { CandidateVideoInterview } from '@types';
import { ApiStatus, UserVideoInterviewStatus } from '@constants';
import {
  formatDateWithTimezone,
  isStatusComplete,
  isStatusLoading,
} from '@utils';

export const VideoInterviewStatus: React.FC<{
  videoInterviewData: CandidateVideoInterview | null;
  resetVideoInterviewApiStatus: ApiStatus;
  resetVideoInterview: () => void;
}> = ({
  videoInterviewData,
  resetVideoInterviewApiStatus,
  resetVideoInterview,
}) => {
  const [isOpenVIResetModal, setIsOpenVIResetModal] = useState(false);

  useEffect(() => {
    if (isStatusComplete(resetVideoInterviewApiStatus)) {
      setIsOpenVIResetModal(false);
    }
  }, [resetVideoInterviewApiStatus]);

  const viStatus = !videoInterviewData
    ? 'Not sent'
    : videoInterviewData.status === UserVideoInterviewStatus.TODO
    ? 'Sent'
    : videoInterviewData.status === UserVideoInterviewStatus.FINISHED &&
      videoInterviewData.videoInterview?.questions.length ===
        videoInterviewData.records.length
    ? 'Finished'
    : videoInterviewData.status === UserVideoInterviewStatus.FINISHED &&
      videoInterviewData.videoInterview?.questions.length !==
        videoInterviewData.records.length
    ? 'Finished (incomplete)'
    : videoInterviewData.status;

  return (
    <>
      <Flex flexDirection="column" alignItems="start" gap={2} width="100%">
        <Typography variant="h5">Status</Typography>
        <Flex
          sx={(theme) => ({
            background: theme.palette.highlight.neutral,
            padding: '8px 12px',
            borderRadius: '6px',
          })}
        >
          <Flex gap={1}>
            <Typography>{viStatus}</Typography>
            <Typography variant="body2" color="text.secondary">
              {formatDateWithTimezone(
                videoInterviewData?.resultDate || videoInterviewData?.sentDate,
              )}
            </Typography>
          </Flex>
          {!!videoInterviewData ? (
            <CustomButton
              size="medium"
              color="secondary"
              label="Reset VI"
              onClick={() => setIsOpenVIResetModal(true)}
              startIcon={<CancelPresentationRoundedIcon fontSize="small" />}
            />
          ) : null}
        </Flex>
      </Flex>

      <VideoInterviewResetModal
        isOpen={isOpenVIResetModal}
        isLoading={isStatusLoading(resetVideoInterviewApiStatus)}
        handleClose={() => setIsOpenVIResetModal(false)}
        handleReset={resetVideoInterview}
      />
    </>
  );
};
