import React, { useMemo } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import AllInboxRoundedIcon from '@mui/icons-material/AllInboxRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import { Flex } from '@components';

import {
  transormCandidateStatus,
  isStatusLoading,
  checkUserCanAssociateCandidate,
  checkUserCanUpdateCandidateStatus,
  getPriorityIcon,
  formatDate,
} from '@utils';
import { IDWHJobOpening, IProfile, IZohoCandidate } from '@types';
import {
  ApiStatus,
  AppRoutes,
  JobOpeningReasonOfClosing,
  JobOpeningStatus,
  SearchParams,
} from '@constants';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: '0.25rem',
  '& svg': {
    width: '1.25rem',
    height: '1.25rem',
  },
}));

export const AssociatedJobOpenings: React.FC<{
  candidateDetails: IZohoCandidate | null;
  profile: IProfile | null;
  data: IDWHJobOpening[];
  apiStatus: ApiStatus;
  visiblePositionsCount: number;
  openJobOpening: (id: string) => void;
  unAssociateCanidate: (id: string) => void;
  handleChangeStatus: (id: string) => void;
}> = ({
  candidateDetails,
  profile,
  data,
  apiStatus,
  visiblePositionsCount,
  openJobOpening,
  unAssociateCanidate,
  handleChangeStatus,
}) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isAbleToUpdateStatus = checkUserCanUpdateCandidateStatus(profile);
  const isAbleToAssociate = checkUserCanAssociateCandidate(profile);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUnAssociateClick = (id: string) => {
    unAssociateCanidate(id);
    handleClose();
  };

  const handleSubmitClientClick = (position: IDWHJobOpening) => {
    navigate({
      pathname: `/${AppRoutes.SUBMISSIONS}`,
      search: createSearchParams({
        [SearchParams.SELECTED_CANDIDATE]: candidateDetails!.id,
        [SearchParams.SUBMISSION_JOBOPENING_ID]: position.id,
      }).toString(),
    });
  };

  const dataToShow = useMemo(
    () => data.slice(0, visiblePositionsCount),
    [data, visiblePositionsCount],
  );

  if (!data.length) {
    return (
      <Typography variant="h3" textAlign="center" py={2}>
        No positions
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: 'highlight.actionable',
      }}
    >
      <TableContainer>
        <Table>
          <TableBody>
            {dataToShow.map((position) => {
              const isOnHold =
                position.Job_Opening_Status === JobOpeningStatus.Closed &&
                position.Reason_of_Closing === JobOpeningReasonOfClosing.OnHold;
              const isClosed =
                position.Job_Opening_Status === JobOpeningStatus.Closed &&
                position.Reason_of_Closing !== JobOpeningReasonOfClosing.OnHold;
              const isPreopen =
                position.Job_Opening_Status === JobOpeningStatus.PreOpen;

              const Icon = getPriorityIcon(
                position.Priority,
                isOnHold,
                isClosed,
                isPreopen,
              );

              return (
                <TableRow key={position.id}>
                  <TableCell>
                    <Flex gap={1}>
                      {Icon && (
                        <Tooltip
                          title={
                            isOnHold
                              ? 'On-Hold'
                              : isClosed
                              ? 'Closed'
                              : position.Priority
                          }
                          placement="top"
                        >
                          <Icon />
                        </Tooltip>
                      )}
                      <Typography variant="body1" color="secondary">
                        {position.Job_Opening_Id}
                      </Typography>
                      <Typography
                        onClick={() => openJobOpening(position.id)}
                        sx={(theme) => ({
                          color: theme.palette.text.primary,
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        })}
                      >
                        {position.Client_Name}: {position.Job_Opening_Name}
                      </Typography>
                    </Flex>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="text.secondary">
                      {transormCandidateStatus(position.Status) || '—'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap variant="body2" color="text.secondary">
                      {formatDate(position.Status_Change_Date) || '—'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Flex>
                      <React.Fragment>
                        <StyledIconButton
                          onClick={() => handleChangeStatus(position.id)}
                          disabled={!isAbleToUpdateStatus}
                        >
                          <Tooltip title="Change status" placement="top">
                            <AllInboxRoundedIcon />
                          </Tooltip>
                        </StyledIconButton>
                        <StyledIconButton
                          onClick={() => handleSubmitClientClick(position)}
                        >
                          <Tooltip title="Submit to client" placement="top">
                            <MailOutlineRoundedIcon />
                          </Tooltip>
                        </StyledIconButton>
                        <>
                          <StyledIconButton
                            id={position.id}
                            disabled={
                              isStatusLoading(apiStatus) || !isAbleToAssociate
                            }
                            onClick={(e) => handleClick(e)}
                          >
                            <MoreVertIcon />
                          </StyledIconButton>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                          >
                            <MenuItem
                              onClick={() =>
                                anchorEl?.id &&
                                handleUnAssociateClick(anchorEl.id)
                              }
                              disabled={
                                isStatusLoading(apiStatus) || !isAbleToAssociate
                              }
                            >
                              Remove association
                            </MenuItem>
                          </Menu>
                        </>
                      </React.Fragment>
                    </Flex>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
