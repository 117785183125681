import { TextField } from '@mui/material';
import { Flex } from '@components';

import { CandidateVideoInterview } from '@types';
import { ReviewLevels, VIResolutionOptionsList } from '@constants';
import { capitalize } from 'lodash';

export const ProvidedFeedbackState: React.FC<{
  videoInterviewData: CandidateVideoInterview;
  onEditFeedbackClick: () => void;
}> = ({ videoInterviewData }) => {
  const resolutionLabel =
    VIResolutionOptionsList.find(
      (e) => e.value === videoInterviewData.resolution,
    )?.label || videoInterviewData.resolution;

  return (
    <Flex flexDirection="column" alignItems="start" gap={2} width="100%">
      <Flex gap={2} width="100%">
        <TextField
          label="Presentational skills"
          variant="outlined"
          value={capitalize(
            videoInterviewData.presentationSkillsLevel as string,
          )}
          sx={(theme) => ({
            input: {
              textFillColor:
                videoInterviewData.presentationSkillsLevel === ReviewLevels.POOR
                  ? '#D32F2F !important'
                  : theme.palette.text.primary,
            },
          })}
          disabled
        />
        <TextField
          label="Functional expertise"
          variant="outlined"
          value={capitalize(
            videoInterviewData.functionalExpertiseLevel as string,
          )}
          sx={(theme) => ({
            input: {
              textFillColor:
                videoInterviewData.functionalExpertiseLevel ===
                ReviewLevels.POOR
                  ? '#D32F2F !important'
                  : theme.palette.text.primary,
            },
          })}
          disabled
        />
        <TextField
          label="English level"
          variant="outlined"
          value={capitalize(videoInterviewData.englishLevel as string)}
          sx={(theme) => ({
            input: {
              textFillColor:
                videoInterviewData.englishLevel === ReviewLevels.POOR
                  ? '#D32F2F !important'
                  : theme.palette.text.primary,
            },
          })}
          disabled
        />
      </Flex>
      <TextField
        label="Notes"
        type="textarea"
        variant="outlined"
        value={videoInterviewData.feedback}
        multiline
        minRows={2}
        disabled
        fullWidth
      />
      <TextField
        label="Final resolution"
        variant="outlined"
        value={resolutionLabel}
        sx={(theme) => ({
          input: {
            textFillColor: videoInterviewData.resolution
              ?.toLowerCase()
              .includes('failed')
              ? '#D32F2F !important'
              : theme.palette.text.primary,
          },
        })}
        disabled
        fullWidth
      />
      <TextField
        label="Reviewer"
        variant="outlined"
        value={videoInterviewData.reviewer}
        disabled
        fullWidth
      />
    </Flex>
  );
};
