import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';

import {
  Avatar,
  Box,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';

import { INote } from '@types';
import { useAppSelector } from '@redux/hooks';
import { authSelectors } from '@redux/auth';
import { CustomButton } from '@components/common';
import { getInitialsFromName } from '@utils';

type CandidateNoteProps = {
  note: INote;
  isLoading: boolean;
  handleDeleteNoteClick: (noteId: string) => void;
  handleEditNoteClick: (noteId: string, note: string) => void;
  handleCopyLink: (noteId: string) => void;
};

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: '0.5rem',
  display: 'flex',
  padding: '1rem 1.5rem',
  gap: '0.5rem',
  borderRadius: '0.25rem',
  boxShadow:
    '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20);',
  backgroundColor: theme.palette.common.white,
  '&.highlight': {
    backgroundColor: theme.palette.background.warningFill,
    animation: 'fadeOut 1s ease 2s forwards',
  },
  '@keyframes fadeOut': {
    to: {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

export const CandidateNote = forwardRef<HTMLLIElement, CandidateNoteProps>(
  (
    {
      note,
      isLoading,
      handleDeleteNoteClick,
      handleEditNoteClick,
      handleCopyLink,
    },
    ref,
  ) => {
    const profileData = useAppSelector(authSelectors.getProfileData);

    const [isEditMode, setEditMode] = useState<boolean>(false);
    const [editedNote, setEditedNote] = useState<string>('');

    const canEdit = useMemo(() => {
      return note.author.email === profileData?.email;
    }, [note, profileData]);

    useEffect(() => {
      handleCancelClick();
    }, [note]);

    const handleCancelClick = () => {
      setEditMode(false);
      setEditedNote('');
    };

    const handleEditModeClick = () => {
      setEditMode(!isEditMode);
      setEditedNote(note.note);
    };

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleEditNoteClick(note.id, editedNote);
    };

    return (
      <StyledBox component="li" flexDirection="column" ref={ref}>
        <Stack gap="0.25rem" flexDirection="row" alignItems="center">
          <Stack flex={1} flexDirection="row" gap="0.5rem">
            <Avatar
              sx={{
                width: '2.5rem',
                height: '2.5rem',
                borderRadius: '0.75rem',
              }}
            >
              {getInitialsFromName(note.author.name)}
            </Avatar>
            <Stack>
              <Typography variant="body1">{note.author.name}</Typography>
              <Typography variant="body2" color="secondary">
                {note.author.role}
              </Typography>
            </Stack>
          </Stack>
          <Typography variant="body2" color="secondary">
            {format(new Date(note.date), 'yyyy-MM-dd hh:mm a')}
          </Typography>
          <Tooltip title="Copy link">
            <IconButton onClick={() => handleCopyLink(note.id)}>
              <InsertLinkRoundedIcon />
            </IconButton>
          </Tooltip>
          {canEdit && !isEditMode && (
            <>
              <Tooltip title="Edit note">
                <IconButton onClick={handleEditModeClick}>
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete note">
                <IconButton onClick={() => handleDeleteNoteClick(note.id)}>
                  <DeleteOutlineRoundedIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Stack>
        {isEditMode ? (
          <Stack component="form" onSubmit={handleFormSubmit} gap="0.5rem">
            <TextField
              fullWidth
              required
              disabled={isLoading}
              name="note"
              multiline
              maxRows={4}
              variant="outlined"
              placeholder="Edit a note..."
              value={editedNote}
              onChange={(e) => setEditedNote(e.target.value)}
              sx={(theme) => ({
                '& .MuiOutlinedInput-root': {
                  background: theme.palette.common.white,
                },
              })}
            />
            <Stack justifyContent="flex-end" gap="0.5rem" flexDirection="row">
              <CustomButton
                label="Update"
                isLoading={isLoading}
                type="submit"
              />
              <CustomButton
                color="secondary"
                label="Cancel"
                onClick={handleCancelClick}
              />
            </Stack>
          </Stack>
        ) : (
          <Typography variant="body1">{note.note}</Typography>
        )}
      </StyledBox>
    );
  },
);
