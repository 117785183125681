import { useState } from 'react';
import { keyBy } from 'lodash';

import { Box, Typography } from '@mui/material';
import { Flex } from '@components';
import ReactPlayer from 'react-player';

import { CandidateVideoInterview } from '@types';
import { apiEndpoints } from '@constants';
import { STATIC_FILES_BASE_ENDPOINT } from 'constants/apiEndpoints';

export const VideoInterviewQuestions: React.FC<{
  videoInterviewData: CandidateVideoInterview | null;
}> = ({ videoInterviewData }) => {
  const [selectedQuestion, setSelectedQuestion] = useState<number | null>(null);

  const questions = videoInterviewData?.videoInterview?.questions;

  const recordByQuestionNumber = keyBy(
    videoInterviewData?.records,
    'questionNumber',
  );

  const selectedRecordUrl = selectedQuestion
    ? recordByQuestionNumber[selectedQuestion]?.fileName || null
    : null;

  return (
    <Flex flexDirection="column" gap={2} alignItems="start" width="100%">
      <Typography variant="h5">Candidate video intro</Typography>
      <Box
        sx={{
          borderRadius: '6px',
          width: '100%',
          minHeight: '220px',
          background: `url(${STATIC_FILES_BASE_ENDPOINT}/projects-images/static-noise.gif)`,
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          '& .react-player': {
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
          },
        }}
      >
        {selectedRecordUrl ? (
          <ReactPlayer
            controls
            playing
            className="react-player"
            width="100%"
            height="100%"
            url={
              selectedRecordUrl
                ? apiEndpoints.videoInterviewFileUrl(selectedRecordUrl)
                : undefined
            }
          />
        ) : (
          <Box
            sx={{
              background: 'rgba(0, 0, 0, 0.5)',
              borderRadius: '6px',
              padding: '8px 16px',
            }}
          >
            <Typography variant="body2" color="text.invertedSecondary">
              {videoInterviewData && !selectedQuestion
                ? 'Please select a question to play'
                : videoInterviewData && selectedQuestion
                ? "Candidate didn't answer this question"
                : !videoInterviewData
                ? 'Candidate hasn’t done VI yet'
                : ''}
            </Typography>
          </Box>
        )}
      </Box>
      {questions?.length && (
        <Flex gap={1} flexDirection="column" width="100%">
          {questions.map((question) => {
            const hasRecord = !!recordByQuestionNumber[question.questionNumber];

            return (
              <Box
                key={question.questionNumber}
                onClick={() => setSelectedQuestion(question.questionNumber)}
                sx={(theme) => ({
                  width: '100%',
                  padding: '0.5rem 1rem 1rem 1rem',
                  cursor: 'pointer',
                  borderRadius: '0.25rem',
                  background:
                    selectedQuestion === question.questionNumber
                      ? theme.palette.highlight.accent
                      : theme.palette.highlight.actionable,
                })}
              >
                <Typography variant="body2" color="text.secondary">
                  Question {question.questionNumber}
                  {hasRecord ? (
                    ''
                  ) : (
                    <Typography component="span" color="text.danger">
                      {' '}
                      (not recorded)
                    </Typography>
                  )}
                </Typography>
                <Typography variant="body1">{question.title}</Typography>
              </Box>
            );
          })}
        </Flex>
      )}
    </Flex>
  );
};
