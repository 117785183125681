import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';

import { LoaderSkeleton, ForbiddenView, Flex } from '@components';
import { VideoInterviewQuestions } from './components/VideoInterviewQuestions';
import { VideoInterviewFeedback } from './components/VideoInterviewFeedback/VideoInterviewFeedback';
import { VideoInterviewSendForm } from './components/VideoInterviewSendForm/VideoInterviewSendForm';
import { VideoInterviewShareWithClient } from './components/VideoInterviewShareWithClient';
import { VideoInterviewStatus } from './components/VideoInterviewStatus/VideoInterviewStatus';

import {
  candidateDetailsSelectors,
  changeCandidateStatus,
  fetchCandidateUserVideoInterview,
  generateClientVideoLink,
  postCandidateResetVideoInterview,
  postUserVideoInterviewReview,
} from '@redux/candidateDetails';
import {
  fetchJobOpeningVideoInterviews,
  optionsSelectors,
} from '@redux/options';

import {
  getInitialCandidateStatusFormValues,
  isStatusForbidden,
  isStatusIdle,
  isStatusLoading,
} from '@utils';
import { ReviewVideoInterviewPayload } from '@types';
import {
  CandidateStatusByVIResolution,
  CandidateStatusFormConfig,
  CandidateStatusStage,
  VIResolution,
} from '@constants';

export const CandidateVideoInterview = () => {
  const dispatch = useAppDispatch();

  const candidateId = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsId,
  );
  const videoInterviewAPIStatus = useAppSelector(
    candidateDetailsSelectors.getCandidateVideoInterviewApiStatus,
  );

  const candidate = useAppSelector(
    candidateDetailsSelectors.getCandidateDetailsData,
  );
  const isPerformingVideoInterviewAction = useAppSelector(
    candidateDetailsSelectors.getCandidateVideoInterviewActionPerforming,
  );
  const videoInterviewData = useAppSelector(
    candidateDetailsSelectors.getCandidateVideoInterviewData,
  );
  const resetVideoInterviewApiStatus = useAppSelector(
    candidateDetailsSelectors.getResetVideoInterviewAPIStatus,
  );
  const {
    data: availableVideoInterview,
    apiStatus: availableVideoInterviewAPIStatus,
  } = useAppSelector(optionsSelectors.getJobOpeningsVIAPIData);

  const generateClientVideoLinkAPIStatus = useAppSelector(
    candidateDetailsSelectors.getGenerateClientVideoLinkApiStatus,
  );

  useEffect(() => {
    if (candidateId && isStatusIdle(videoInterviewAPIStatus)) {
      dispatch(fetchCandidateUserVideoInterview(candidateId));
    }
  }, [candidateId, videoInterviewAPIStatus]);

  useEffect(() => {
    if (isStatusIdle(availableVideoInterviewAPIStatus)) {
      dispatch(fetchJobOpeningVideoInterviews());
    }
  }, [availableVideoInterviewAPIStatus]);

  const currentVideoInterview = videoInterviewData[0] || null;

  const reviewVideoInterview = useCallback(
    async (payload: ReviewVideoInterviewPayload) => {
      if (!currentVideoInterview || !candidateId || !candidate) return;

      await dispatch(
        postUserVideoInterviewReview({
          candidateId,
          videoInterviewId: currentVideoInterview.id,
          ...payload,
        }),
      );

      const nextCandidateStatus =
        CandidateStatusByVIResolution[payload.resolution as VIResolution];

      if (nextCandidateStatus) {
        const initialFormValues = getInitialCandidateStatusFormValues(
          candidate,
          null,
          CandidateStatusStage.VideoInterview,
        );
        const getFormData = CandidateStatusFormConfig[nextCandidateStatus]!;
        const { autoupdatedFields } = getFormData(
          CandidateStatusStage.VideoInterview,
          initialFormValues,
        );

        await dispatch(
          changeCandidateStatus({
            candidateId,
            payload: {
              transitions: [
                [
                  {
                    stage: CandidateStatusStage.VideoInterview,
                    status: nextCandidateStatus,
                    jobOpeningId: null,
                  },
                ],
              ],
              statusesData: [
                {
                  stage: CandidateStatusStage.VideoInterview,
                  status: nextCandidateStatus,
                  Stage: CandidateStatusStage.VideoInterview,
                  ...autoupdatedFields,
                },
              ],
            },
          }),
        );
      }
    },
    [candidateId, currentVideoInterview],
  );

  const resetVideoInterview = useCallback(() => {
    if (candidateId) {
      dispatch(
        postCandidateResetVideoInterview({
          candidateId,
          videoInterviewId: currentVideoInterview.id,
        }),
      );
    }
  }, [candidateId, currentVideoInterview]);

  const handleGenerateClick = useCallback(() => {
    if (candidateId && currentVideoInterview) {
      dispatch(
        generateClientVideoLink({
          candidateId,
          videoInterviewId: currentVideoInterview.id,
        }),
      );
    }
  }, [candidateId, currentVideoInterview]);

  if (
    isStatusLoading(videoInterviewAPIStatus) ||
    isStatusLoading(availableVideoInterviewAPIStatus)
  ) {
    return <LoaderSkeleton />;
  }

  if (isStatusForbidden(videoInterviewAPIStatus)) {
    return <ForbiddenView />;
  }

  return (
    <Flex
      flexDirection={currentVideoInterview ? 'row' : 'column'}
      gap={currentVideoInterview ? 5 : 8}
      justifyContent="space-between"
      alignItems={currentVideoInterview ? 'start' : 'center'}
    >
      <Flex
        flexDirection="column"
        gap={5}
        alignItems="start"
        justifyContent="start"
        width="50%"
      >
        <VideoInterviewQuestions videoInterviewData={currentVideoInterview} />
        <VideoInterviewShareWithClient
          isLoading={isStatusLoading(generateClientVideoLinkAPIStatus)}
          currentVideoInterview={currentVideoInterview}
          handleGenerateClick={handleGenerateClick}
        />
        <VideoInterviewSendForm
          availableVideoInterview={availableVideoInterview}
          currentVideoInterview={currentVideoInterview}
        />
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="start"
        justifyContent="end"
        gap={2}
        width="50%"
      >
        <VideoInterviewStatus
          videoInterviewData={currentVideoInterview}
          resetVideoInterviewApiStatus={resetVideoInterviewApiStatus}
          resetVideoInterview={resetVideoInterview}
        />
        <VideoInterviewFeedback
          candidateFirstName={candidate?.First_Name || ''}
          isPerformingVideoInterviewAction={isPerformingVideoInterviewAction}
          videoInterviewData={currentVideoInterview}
          postVideoInterviewReview={reviewVideoInterview}
        />
      </Flex>
    </Flex>
  );
};
