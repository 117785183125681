import React from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';
import {
  Box,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DragHandleIcon from '@mui/icons-material/DragHandle';

interface IDraggableItem {
  label: string;
  value: string;
}

export type Props<T extends IDraggableItem> = {
  items: T[];
  onDragEnd: (result: DropResult) => void;
  onDelete: (item: T) => void;
};

export const DraggableList = <T extends IDraggableItem>({
  items,
  onDragEnd,
  onDelete,
}: Props<T>) => {
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    onDragEnd(result);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <List
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{ padding: 0, minHeight: '100px' }}
          >
            {items.map((item, index) => (
              <Draggable
                key={item.value}
                draggableId={item.value}
                index={index}
              >
                {(provided, snapshot) => (
                  <Box
                    sx={{
                      listStyle: 'none',
                      display: 'flex',
                      alignItems: 'center',
                      paddingX: '12px',
                      backgroundColor: snapshot.isDragging
                        ? '#3F8CFF14'
                        : 'inherit',
                      '&:focus': {
                        backgroundColor: '#3F8CFF14',
                        '& .MuiListItemIcon-root': {
                          color: '#3F8CFF',
                        },
                      },
                      '&:hover': {
                        backgroundColor: '#3F8CFF14',
                        '& .MuiListItemIcon-root': {
                          color: '#3F8CFF',
                        },
                      },
                    }}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <ListItemIcon sx={{ minWidth: '32px' }}>
                      <DragHandleIcon color="secondary" fontSize="small" />
                    </ListItemIcon>

                    <ListItemText primary={item.label} />

                    <IconButton
                      onClick={() => onDelete(item)}
                      sx={{ marginLeft: 'auto' }}
                    >
                      <DeleteOutlineIcon
                        color="secondary"
                        fontSize="small"
                        sx={{
                          '&:hover': {
                            color: '#3F8CFF',
                          },
                        }}
                      />
                    </IconButton>
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};
