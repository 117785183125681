import React, { useMemo, useState } from 'react';
import { format } from 'date-fns';
import { mapValues } from 'lodash';

import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import { Flex } from '@components';
import { CandidateStages } from './CandidateStages';

import {
  getCandidatesByStage,
  getDateColumnTitleBasedOnStage,
  isStatusLoading,
  transformDWHCandidateToCandidatesByStage,
} from '@utils';
import {
  ApiStatus,
  CandidateStage,
  CandidateStageLabel,
  CandidateUpcomingEvents,
  CandidateUpcomingEventsLabel,
  CandidateStagesOrder,
} from '@constants';
import { IDWHCandidate } from '@types';
import { CandidatesDetailsByStage } from './CandidatesDetailsByStage';

const getHeading = (number: number, stage: string) => {
  switch (stage) {
    case CandidateStage.APPLIED:
      return `${number} candidates applied`;
    case CandidateStage.TT_PASSED:
      return `${number} candidates passed test task`;
    case CandidateStage.IC_SCHEDULED:
      return `${number} candidates with IC scheduled`;
    case CandidateStage.IC_DONE:
      return `${number} candidates did IC`;
    case CandidateStage.IC_PASSED:
      return `${number} candidates passed intro call`;
    case CandidateStage.TI_PASSED:
      return `${number} candidates passed tech interview`;
    case CandidateStage.SUBMITTED:
      return `${number} candidates submitted`;
    case CandidateStage.NEW_HOME:
      return `${number} eligible NewHome members`;
    case CandidateUpcomingEvents.IC:
      return `${number} candidates waiting for IC`;
    case CandidateUpcomingEvents.TI:
      return `${number} candidates waiting for TI`;
    default:
      return 'Candidates';
  }
};

export const PotentialCandidates: React.FC<{
  candidatesData: IDWHCandidate[];
  candidatesAPIStatus: ApiStatus;
}> = ({ candidatesData, candidatesAPIStatus }) => {
  const [candidatesStage, setCandidatesStage] = useState<string | null>(null);

  const candidatesByStage = useMemo(() => {
    const res: Record<string, IDWHCandidate[]> = {};

    CandidateStagesOrder.forEach((stage) => {
      const candidatesByStage = getCandidatesByStage(candidatesData, stage);

      res[stage] = candidatesByStage;
    });

    res[CandidateUpcomingEvents.IC] = candidatesData.filter(
      (candidate) =>
        !!candidate.Date_of_Prescreen_Planned &&
        candidate.Date_of_Prescreen_Planned > format(new Date(), 'yyyy-MM-dd'),
    );

    res[CandidateUpcomingEvents.TI] = candidatesData.filter(
      (candidate) =>
        candidate.candidateStage === CandidateStage.IC_PASSED &&
        !!candidate.Date_Of_Tech_Interview_Planned &&
        candidate.Date_Of_Tech_Interview_Planned >
          format(new Date(), 'yyyy-MM-dd'),
    );

    return mapValues(res, (candidates, stage) =>
      candidates.map((e) =>
        transformDWHCandidateToCandidatesByStage(
          e,
          stage as CandidateStage | CandidateUpcomingEvents,
        ),
      ),
    );
  }, [candidatesData]);

  const candidateStages = useMemo(
    () =>
      CandidateStagesOrder.map((stage) => {
        return {
          label: CandidateStageLabel[stage],
          value: stage,
          amount: candidatesByStage[stage].length,
          amountIcon:
            stage === CandidateStage.NEW_HOME ? (
              <TransferWithinAStationIcon
                sx={(theme) => ({
                  color: theme.palette.button.secondary.textDisabled,
                  fontSize: '20px',
                })}
              />
            ) : undefined,
        };
      }),
    [candidatesByStage],
  );

  const candidateEvents = useMemo(() => {
    return [
      {
        label: CandidateUpcomingEventsLabel[CandidateUpcomingEvents.IC],
        value: CandidateUpcomingEvents.IC,
        amount: candidatesByStage[CandidateUpcomingEvents.IC].length,
      },
      {
        label: CandidateUpcomingEventsLabel[CandidateUpcomingEvents.TI],
        value: CandidateUpcomingEvents.TI,
        amount: candidatesByStage[CandidateUpcomingEvents.TI].length,
      },
    ];
  }, [candidatesByStage]);

  return (
    <Flex flexDirection="column" gap={3} alignItems="start" width="100%">
      <Flex gap={3} flexWrap="wrap">
        <Flex flexDirection="column" alignItems="start" gap={1} flex={1}>
          <CandidateStages
            loading={isStatusLoading(candidatesAPIStatus)}
            stages={candidateStages}
            activeStage={candidatesStage}
            onClick={setCandidatesStage}
            stageHeaderTitle="Potential candidates"
          />
        </Flex>
        <Flex flexDirection="column" alignItems="start" gap={1} flex={1}>
          <CandidateStages
            loading={isStatusLoading(candidatesAPIStatus)}
            stages={candidateEvents}
            activeStage={candidatesStage}
            onClick={setCandidatesStage}
            stageHeaderTitle="Upcoming events"
          />
        </Flex>
      </Flex>
      {!!candidatesStage && (
        <CandidatesDetailsByStage
          title={getHeading(
            candidatesByStage[candidatesStage].length,
            candidatesStage,
          )}
          data={candidatesStage ? candidatesByStage[candidatesStage] : null}
          dateColumnTitle={getDateColumnTitleBasedOnStage(
            candidatesStage as CandidateStage | CandidateUpcomingEvents,
          )}
          onClick={setCandidatesStage}
        />
      )}
    </Flex>
  );
};
